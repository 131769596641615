<template>
  <v-app class="">
    <v-container class="tw-w-full sm:tw-w-1/2 md:tw-max-w-md tw-mx-auto tw-h-full tw-flex tw-justify-center tw-items-center">
      <v-overlay :value="isProcessing" absolute z-index="1" />
      <v-row>
        <v-col cols="12">
          <!-- head -->
          <Header title="login" />
          <!-- end head -->
        </v-col>
        <!-- Body -->
        <v-col cols="12">
          <v-expand-transition v-if="!success">
            <SignInForm 
              :form="form"
              :doLogin="doLogin"
              :doUpdateData="doUpdateData"
              :isProcessing="isProcessing"
            />
          </v-expand-transition>
        </v-col>
        <v-col sm="12">
          <TermsPrivacy title="logging" />
        </v-col>
        <!-- end Body -->
      </v-row>
    </v-container>
    <Credits />
    <base-snack-bars :objects.sync="snackBars" />
  </v-app>
</template>

<script>
// atoms
import Title from '@/components/atoms/Title'
import TextNormal from '@/components/atoms/TextNormal'

//utilities
import {
  cloneDeep,
  get
} from 'lodash'
// import { Auth } from 'aws-amplify'
import { initComponent } from '@/lib'

import {
  mapState,
  mapActions,
  mapMutations
} from 'vuex'

import {
  REQUEST_LOGIN,
  // REQUEST_MFA,
  RESET_LOGIN_STATE,
  UPDATE_LOGIN_STATE
} from '@/store/modules/login'
import {
  ROUTE_DASHBOARD,
} from '@/constants'



export default initComponent ('SignIn', {
  isPage: true,
  components: {
    Title,
    TextNormal,
    SignInForm: () => import('./components/SignInForm'),
    TermsPrivacy: () => import('../components/TermsPrivacy'),
    Credits: () => import('../components/Credits'),
    Header: () => import('../components/Header'),
  },
  data: () => ({
    //not state management data
    success: false,
  }),
  computed: {
    ...mapState({
      isProcessing: (state) => state.login.isProcessing,
      form: (state) => state.login.form,
    }),
  },
  methods: {
    async onReady () {
      // let user = await Auth.currentSession()
      // const { attributes } = user;
      // console.log(user);
      
      return Promise.all([
        this.doUpdateData({
          ...(get(this.$store.state.login, 'form.scenario', {})),
          // mfa: SCENARIO_MFA_LOGIN,
        }, 'scenario'),
      ])
    },
    async onLoginSuccess () {
      this.$store.commit(RESET_LOGIN_STATE)
      const { query = {} } = this.$route
      this.success = true
      if (query.role === 'visitor') {
        this.$router.push({ name: ROUTE_DASHBOARD, params: query })
        return
      }
      this.$router.push({ name: ROUTE_DASHBOARD })
    },
    ...mapActions({
      async doLogin (dispatch) {
        dispatch(REQUEST_LOGIN)
      },
    }),
    doUpdateData (value, key) {
      const { commit, state } = this.$store
      const form = cloneDeep(state.login.form)
      form[key] = value
      return commit(UPDATE_LOGIN_STATE, { form })
    },
    ...mapMutations({}),
    // submit () {
    //   this.$refs.observer.validate()
    //   this.$router.push({ name: ROUTE_DASHBOARD })
    // },
    // clear () {
    //   this.form = {}
    //   this.$refs.observer.reset()
    // },
  },
})
</script>

<style lang="sass" scoped>
.theme--light.v-application
  background: #fff !important

</style>